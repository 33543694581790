<template>
    <div>
        <page-title :heading="$t('settings.lang_cardTemplate')"
                    :subheading="$t('settings.lang_createCardTemplate')"
                    :icon=icon
                    show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <VoucherbeeCardTemplateCreateComponent/>
        </div>
    </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import VoucherbeeCardTemplateCreateComponent from "@/components/settings/moduleSettings/onlinegiftcardsale/cardtemplate/VoucherbeeCardTemplateCreate.vue";
    export default {
        name: "VoucherbeeCardTemplateCreate",
        components:{
            PageTitle,
            VoucherbeeCardTemplateCreateComponent
        },
        data(){
           return{
                icon: 'pe-7s-monitor icon-gradient bg-tempting-azure',
           }
        },
    }
</script>

<style scoped>

</style>